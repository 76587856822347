import Vue from 'vue';
import VueRouter from 'vue-router';
import DeviceErrApi from "../api/deviceerr";
Vue.use(VueRouter)

const routes = [
  {
    path: '/newlist',
    component: () => import('../views/HomeView.vue'),
    children:[
      {
        path: 'gamecenter',
        name: 'gamecenter',
        meta: {
          title: '游戏中心'
        },
        component: () => import('../views/GameCenter.vue'),
      },
      {
        path: 'mycenter',
        name: 'mycenter',
        meta: {
          title: '我参与的'
        },
        component: () => import('../views/MyCenter.vue'),
      },
    ]
  },
  {
    path: '/try/try_cpl_plus',
    name: 'home',
    meta: {
      title: '详情'
    },
    component: () => import('../views/HomeDetail.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      title: '我的榜单活动'
    },
    component: () => import('../views/ActiVity.vue'),
  },
  {
    path: '/kf',
    name: 'home',
    meta: {
      title: '客服'
    },
    component: () => import('../views/HomeViews.vue')
  },
  {
    path: '/awards',
    name: 'awards',
    meta: {
      title: '我的榜单活动'
    },
    component: () => import('../views/AwardS.vue'),
  },
  {
    path:"/try/try_cpl_strategy.aspx",
    name:"IntroducTion",
    meta: {
      title: '试玩流程攻略'
    },
    component: () => import('../views/IntroducTion.vue')
  },
  {
    path: '/message',
    name: 'message',
    meta: {
      title: '消息中心'
    },
    component: () => import('../views/MesSage.vue'),
  },
  {
    path: '/try/search',
    name: 'search',
    meta: {
      title: '搜索'
    },
    component: () => import('../views/SearchFor.vue'),
  },
  {
    path: '/AdRanking',
    name: 'AdRanking',
    meta: {
      title: '活动榜单'
    },
    component: () => import('../views/adRanking.vue'),
  },
  {
    path: '/adRewardlog',
    name: 'adRewardlog',
    meta: {
      title: '我的获奖记录'
    },
    component: () => import('../views/adRewardlog.vue'),
  },
  {
    path: '/Limited',
    name: 'Limited',
    component: ()=>import("../views/LimitEd.vue"),
    meta: {
      title: '限时活动'
    }
  },
  {
    path: '/Limitedlist',
    name: 'Limitedlist',
    component: ()=>import("../views/LimitedList.vue"),
    meta: {
      title: '我的奖励'
    }
  },
  {
    path: '/submitGameId',
    name: 'submitGameId',
    component: ()=>import('../views/submitGameId.vue')
  },
  {
    path: '/Limited',
    name: 'Limited',
    component: ()=>import("../views/LimitEd.vue"),
    meta: {
      title: '限时活动'
    }
  },
  {
    path: '/Limitedlist',
    name: 'Limitedlist',
    component: ()=>import("../views/LimitedList.vue"),
    meta: {
      title: '我的奖励'
    }
  },
  {
    path: '/read',
    name: 'read',
    component: ()=>import("../views/MyRead.vue"),
    meta: {
      title: '资讯阅读'
    }
  },
  {
    path: '/readlist',
    name: 'readlist',
    component: ()=>import("../views/MyReadList.vue"),
    meta: {
      title: '明细'
    }
  },
  {
    path: '/gamedemo',
    name: 'gamedemo',
    component: ()=>import("../views/gameDemo.vue"),
    meta: {
      title: '游戏试玩'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(to.query.ptype !== "1"){
    next();
    return false;
  }
  if((!to.query.deviceid) || (to.query.deviceid.indexOf("JHL_") !== -1 && to.query.missdevice ==="ios")){
    DeviceErrApi.getdevicepre(to.path, to.query).then(res_ => {
      if(to.query.deviceid === res_.deviceid){
        next();
        return false;
      }
      next({
        name: 'gamecenter',
        query: {
          deviceid:res_.deviceid,
          keycode:res_.keycode,
          missdevice:res_.missdevice,
          msaoaid:res_.deviceid,
          appid:res_.appid,
          appsign:res_.appsign,
          ptype:res_.ptype,
          simid:res_.simid,
        }
      })
    })
  }else{
    next();
  }
  try {
    if(window.android){
      window.android.interceptorUrl(to.fullPath);
    }
  } catch (error) {
    console.log(error)
  }
})
export default router
